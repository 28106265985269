<template>
  <div class="password-input">
    <CreateNewPasswordModal
      @show-modal="closeModal($event)"
      :showing-modal="showModal"/>
    <form class="form">
      <BaseInput
        class="form-group"
        placeholder="Password"
        v-model="formData.password"
        :isPassword="true"
        :hasError="$v.formData.password.$error"
      >
        <template slot="error-message">
          <span v-if="!$v.formData.password.required">Field is required</span>
          <span v-else-if="!$v.formData.password.minLength">
            Password must have at least
            {{ $v.formData.password.$params.minLength.min }} letters
          </span>
        </template>
      </BaseInput>
      <BaseInput
        class="form-group"
        placeholder="Confirm password"
        v-model="formData.repeatPassword"
        :isPassword="true"
        :hasError="$v.formData.repeatPassword.$error"
      >
        <template slot="error-message">
          <span v-if="!$v.formData.repeatPassword.sameAsPassword">
            Passwords must be identical
          </span>
        </template>
      </BaseInput>
      <div
        class="form-group"
        :class="{ 'form-group-error': $v.formData.selectSecretQuestion.$error }"
      >
      </div>
      <div class="form-group form-group_submit action-change-password">
        <button
          class="button change-password button-continue"
          @click.prevent="changePassword"
        >
          Continue
        </button>
        <button
          class="button change-password button-cancel"
          @click.prevent="login"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import secretQuestions from '@constants/secretQuestions';
import CreateNewPasswordModal from '@components/Modals/CreateNewPasswordModal.vue';

const { required, sameAs, minLength } = require('vuelidate/lib/validators');

export default {
  components: { CreateNewPasswordModal },
  name: 'CreatePassword',
  mixins: [validationMixin],
  validations: {
    formData: {
      password: {
        required,
        minLength: minLength(4),
      },
      repeatPassword: {
        sameAsPassword: sameAs('password'),
      },
      secretAnswer: {
        required,
      },
      selectSecretQuestion: {
        required,
      },
    },
  },
  data: () => ({
    questions: secretQuestions,
    showModal: false,
    formData: {
      password: null,
      repeatPassword: null,
      secretAnswer: null,
      selectSecretQuestion: null,
      code: '',
      email: '',
    },
  }),
  watch: {
    formData: {
      // eslint-disable-next-line no-unused-vars
      handler() {
        if (this.formData.secretAnswer) {
          this.formData.secretAnswer = this.formData.secretAnswer.replace(/\s+/gi, '');
        }
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    closeModal(value) {
      this.showModal = value;
    },
    login() {
      // this.$router.push('/questionnaire');
      this.$router.push('login');
      // this.$store.dispatch('showComponent/gotToStep3');
    },
    getCode(param) {
      const urlString = window.location.href;
      const url = new URL(urlString);
      return url.searchParams.get(param);
    },
    changePassword() {
      this.$v.$touch();
      this.formData.code = this.getCode('code');
      this.formData.email = this.getCode('email');
      if (!this.$v.$invalid) {
        this.$emit('submit', this.formData);
      }
      return this.$api.auth.newPassword(this.formData).then(() => {
        this.showModal = true;
      //  localStorage.
        //      this.$route.push('login');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .action-change-password {
    display: flex;
    justify-content: space-around;
    margin-top: 45px;
  }
  .form-group_submit {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    flex-direction: row;
  }

  .questions-secret-list {
    height: 50px !important;
  }

  .password-input .v-input__slot {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #ddd !important;
  }

  .password-input .v-select__slot > label {
    padding-left: 10px;
  }
  .password-input #select {
    padding-left: 10px;
    color: #a89c99;
  }

  .password-input .v-select__selections {
    margin-left: 10px;
    color: #a89c99;
  }

  .button-cancel {
    background: #F3F4F6;
    color: #A7A7A7;
    border: 1px solid #A7A7A7;
    font-size: 20px;
    font-weight: bold;
    font-family: $newDefaultFont;
    padding: 12px 55px 12px 55px;
    border-radius: 5px;
  }
  .button-continue {
    background: #7811C9;
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: $newDefaultFont;
    border-radius: 5px;
    padding: 12px 55px 12px 55px;
  }

</style>
